@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

body {
	$primary: #012345;
	$secondary: #111111;
	$success: #222222;
	$dark: #000;

	$theme-colors: (
		'primary': $primary,
		'secondary': $secondary,
		'success': $success,
		'danger': $danger,
		'info': $indigo,
		'dark': $dark,
		'light': $light,
	);

	@each $color, $value in $theme-colors {
		--#{$variable-prefix}#{$color}: #{$value};
	}

	$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');
	@each $color, $value in $theme-colors-rgb {
		--#{$variable-prefix}#{$color}-rgb: #{$value};
	}

	$body-color: #eeeeee;
	$body-bg: #263c5c;

	--#{$variable-prefix}body-color: #{$body-color};
	--#{$variable-prefix}body-bg: #{$body-bg};
}

@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/helpers/position';
@import '~bootstrap/scss/bootstrap-utilities';

.modal-header {
	font-size: 2em;
	> button {
		font-size: initial;
	}
}
.modal-content {
	background-color: #000;
}
.btn-close {
	filter: invert(100%);
}
